import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { Button, Dropdown, Typography } from '@commonsku/styles';

import { formatMoney, parseMysqlDate, oauth, openWindowWithPost, getAbsoluteUrl } from '../../utils';

import { getStatusOptions, getUserOptions } from '../../selectors/dropdowns';
import { getAddress, getInventoryByShopId, getOrderByNumber } from '../../selectors';

import {
  createAssignToClientPopup,
  createJoinOrdersPopup,
  createPartialPopup,
  createAddToOrderPopup,
  createValidateConfirmationPopup,
  createErrorPopup,
  createNoticePopup,
} from '../../actions/popup';
import { loadShopOrders, createDeletePartials } from '../../actions/shop';

import Select from '../Select';
import Address from '../Address';
import LabelledInput from '../LabelledInput';

import { window } from '../../global';
import { ExportViewerPortal, ExportViewer } from '../export-report/ExportViewer';
import { createLoadOrder } from '../../actions/order';
import { loadInventory } from '../../actions/inventory';
import { ExportViewerPopup } from '../export-report/ExportViewerPopup';

class Orders extends Component {

  constructor(props) {
    super(props);

    this.state = {
      exportTaskIds: [],
      status_id: 'ALL',
      sales_rep_id: 'ALL',
      sort: { date_created: 'DESC' },
      loading: true,
      type: props.shop.aggregate == 1 || !props.shop.client_id ? 'staging' : 'completed',
      selected: [],
      rows: [],
      keyword: '',
      exportingPdf: false,
    };

    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.exportPartialsCSV = this.exportPartialsCSV.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.exportPartialsPDF = this.exportPartialsPDF.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.handleLoadShopOrders();
    if(this.props.shop.in_aggregating == 1) {
      this.props.onCreateNoticePopup();
      this.checkShopAggregateStatus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.shop.partials.length !== this.props.shop.partials.length) {
      this.setState({
        selected: [],
        rows: nextProps.shop.partials
      }, function() {
        this.handleSortOrders(Object.keys(this.state.sort)[0], false);
      });
    }
    if(nextProps.shop.in_aggregating == 1) {
      this.checkShopAggregateStatus();
    }
  }

  componentDidMount() {
    const { order: { loaded, order_id }, loadOrder, shop, inventory, loadInventory } = this.props;
    if (!loaded) {
      loadOrder(order_id);
    }
    if (!!shop.inventory_id && !inventory) {
      loadInventory();
    }
  }

  checkShopAggregateStatus() {
    const { shop } = this.props;
    const shop_id = shop.shop_id;

    this.interval = setInterval(function () {
        return oauth('GET', `shop/${shop_id}`, {}).then(({ json }) => {
          if(json.shop.in_aggregating == 0) {
            window.location = `/shop/${shop.shop_number}/orders`;
          }
        });
    }, 15000);
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    );
  }

  handleChangeInput(value) {
    this.setState({ keyword: value, loading: true });
  }

  handleSearch = debounce(function () {
    this.handleChangeFilter(this.state.keyword, 'keyword');
  }, 500);

  handleReset() {
    this.setState({
      status_id: 'ALL',
      sales_rep_id: 'ALL',
      sort: { date_created: 'DESC' },
      loading: false,
      keyword: ''
    }, function() {
      this.handleLoadShopOrders();
    });
  }

  isNotAbandonedOrder(order) {
    return (
      +order.checked_out_amount <= 0 ||
      !order.stripe_session_id ||
      +order.stripe_payment_fulfilled === 1
    );
  }

  filteredRows(type, shop){
    switch (type){
      case 'completed':
        return shop.sales_orders.filter(so => !!so.client_id && this.isNotAbandonedOrder(so));

      case 'staging':
        return shop.aggregate === 1
        ? shop.partials.filter(so => this.isNotAbandonedOrder(so))
        : shop.sales_orders.filter(so => !so.client_id && this.isNotAbandonedOrder(so));

      case 'charged':
        return shop.inventory_id
        ? shop.inventory_purchases.filter(ip => ip.order_type === 'INVOICE')
        : shop.invoices;

      default:
        return [];
    }
  }

  handleLoadShopOrders() {
    this.setState({ loading: true }, function() {
      this.props.loadShopOrders().then(
        () => {
          const { shop } = this.props;
          const { type } = this.state;
          const rows = this.filteredRows(type, shop);

          this.setState({
            rows
          }, function() {
            this.handleSortOrders(Object.keys(this.state.sort)[0], false);
          });
        }
      );
    });
  }

  handleChangeFilter (e, field) {
    const { shop } = this.props;
    const regex = new RegExp(this.state.keyword, "gi");

    this.setState({ [field]: e, loading: true }, function() {
      if(field === 'type') {
        this.handleLoadShopOrders();
      }else {
        const original_rows = this.filteredRows(this.state.type, shop);

        this.setState({
          rows: original_rows.filter(o => this.state.status_id !== 'ALL' ? this.state.status_id === o.status_id : true)
            .filter(o => this.state.sales_rep_id !== 'ALL' ? this.state.sales_rep_id === o.sales_rep_id : true)
            .filter(o => this.state.keyword !== '' && o.partial_keywords !== '' ? regex.test(o.partial_keywords) : this.state.keyword !== '' && o.partial_keywords === '' ? false : true)
        }, function() {
          this.handleSortOrders(Object.keys(this.state.sort)[0], false);
        });
      }
    });
  }

  handleSortOrders(column, is_click=true) {
    if(typeof column !== 'string') {
      return;
    }

    const { rows } = this.state;
    let sort_order;
    if(is_click) {
      sort_order = this.state.sort && this.state.sort.hasOwnProperty(column) && this.state.sort[column] === 'DESC' ? 'ASC' : 'DESC';
    }else{
      sort_order = this.state.sort[column];
    }

    this.setState({ sort: { [column]: sort_order } }, function() {
      let sort_rows;
      const sorting_rows = (sort_rows, column, sort_order) => {
        if(['client_contact', 'billing_contact'].indexOf(column) !== -1) {
          if (sort_order === 'ASC') {
            sort_rows.sort((a, b) => {
              let x, y;
              if(column === 'client_contact') {
                x = a.contact_first_name.toLowerCase() + a.contact_last_name.toLowerCase();
                y = b.contact_first_name.toLowerCase() + b.contact_last_name.toLowerCase();
              }else if(column === 'billing_contact') {
                x = a.billing_contact_first_name.toLowerCase() + a.billing_contact_last_name.toLowerCase();
                y = b.billing_contact_first_name.toLowerCase() + b.billing_contact_last_name.toLowerCase();
              }

              if(x < y) { return -1; }
              if(x > y) { return 1; }
              return 0;
            });
          } else {
            sort_rows.sort((a, b) => {
              let x, y;
              if(column === 'client_contact') {
                x = a.contact_first_name.toLowerCase() + a.contact_last_name.toLowerCase();
                y = b.contact_first_name.toLowerCase() + b.contact_last_name.toLowerCase();
              }else if(column === 'billing_contact') {
                x = a.billing_contact_first_name.toLowerCase() + a.billing_contact_last_name.toLowerCase();
                y = b.billing_contact_first_name.toLowerCase() + b.billing_contact_last_name.toLowerCase();
              }

              if(x > y) { return -1; }
              if(x < y) { return 1; }
              return 0;
            });
          }
        } else if(column === 'date_created' || column === 'charged_date') {
          if (sort_order === 'ASC') {
            sort_rows.sort((a, b) => Date.parse(a.date_created) - Date.parse(b.date_created));
          }else{
            sort_rows.sort((a, b) => Date.parse(b.date_created) - Date.parse(a.date_created));
          }
        } else if(['total_subtotal', 'checked_out_amount', 'job_number', 'charged_fee'].indexOf(column) > -1) {
          //number sorting
          if (sort_order === 'ASC') {
            sort_rows.sort((a, b) => parseFloat(a[column] || 0) - parseFloat(b[column] || 0));
          } else {
            sort_rows.sort((a, b) => parseFloat(b[column] || 0) - parseFloat(a[column] || 0));
          }
        } else {
          //string sorting
          if (sort_order === 'ASC') {
            sort_rows.sort((a, b) => {
              let x = (a[column] || '').toLowerCase();
              let y = (b[column] || '').toLowerCase();
              if (x < y) { return -1; }
              if (x > y) { return 1; }
              return 0;
            });
          } else {
            sort_rows.sort((a, b) => {
              let x = (a[column] || '').toLowerCase();
              let y = (b[column] || '').toLowerCase();
              if (x > y) { return -1; }
              if (x < y) { return 1; }
              return 0;
            });
          }
        }

        return sort_rows;
      };
      switch (column) {
        default:
          sort_rows = sorting_rows(rows, column, sort_order);
          break;
      }
      this.setState({ rows: sort_rows, loading: false });
    });
  }

  exportPartialsCSV(selected) {
    if (!selected.length) return;
    const obj = {
      'report_type': 'partials',
      'export_all': true,
      'order_ids': JSON.stringify(selected)
    };

    if (selected.length < 50) {
      openWindowWithPost("/export_report.php", obj);
    } else {
      oauth('POST', `export-report-task`, obj).then(({ json }) => {
        this.setState((prev) => {return {
          ...prev,
          exportTaskIds: [...prev.exportTaskIds, json.export_report_task.export_report_task_id],
        };});
      });
    }
  }

  exportPartialsPDF(selected) {
    if (!selected.length) return;
    const obj = {
      'print_checkouts': 1,
      'actionName': 'partials-pdf',
      'shop_id': this.props.shop.shop_id,
      'order_ids': JSON.stringify(selected),
    };

    if (selected.length < 50) {
      const formData = new FormData();
      for (let key in obj) {
        if (key === 'order_ids') {
          selected.forEach(v => {
            formData.append('order_ids[]', v);
          });
        } else {
          formData.append(key, obj[key]);
        }
      }
      this.setState({ exportingPdf: true, });
      oauth('POST', 'export', {}, formData, {headers: {'Accept': 'application/pdf'}}, (response) => {
        if (!response.ok) {
          return 'Unable to generate PDF';
        }
        return response.blob();
      }).then((file) => {
        this.setState({ exportPdfFile: file });
      }).catch(() => {
        this.setState({ exportPdfFile: 'Unable to generate PDF' });
      });
    } else {
      oauth('POST', 'export-report-task', obj).then(({ json }) => {
        this.setState((prev) => {
          const exportReportTask = json?.export_report_task;
          const isComplete = Boolean(exportReportTask?.result) ||
            Boolean(exportReportTask?.date_finished) ||
            Boolean(exportReportTask?.error) ||
            Boolean(exportReportTask?.date_failed);
          return {
            ...prev,
            exportingPdf: !isComplete,
            exportReportTask: exportReportTask,
          };
        });
      });
    }
  }

  renderSortColumnHeader(column, label) {
    const headerStyle= {
      top: '120px',
      background: '#EDF2F5'
    };

    return (
      <td style={['total_subtotal', 'checked_out_amount', 'charged_fee'].indexOf(column) !== -1 ? {...headerStyle, textAlign: 'right'} : headerStyle} className="table-cell-sticky" onClick={e => { e.preventDefault(); this.handleSortOrders(column); }}>
        <span style={{ paddingRight: '5px' }}>{label}</span>
        <span>
          {this.state.sort && this.state.sort[column] && this.state.sort[column] === 'ASC' ?
            <span>&#x25B2;</span>
          : null}
          {this.state.sort && this.state.sort[column] && this.state.sort[column] === 'DESC' ?
            <span>&#x25BC;</span>
          : null}
        </span>
      </td>
    );
  }

  renderHeader() {
    const { shop } = this.props;
    const { selected, rows } = this.state;

    const headerStyle= {
      top: '120px',
      background: '#EDF2F5'
    };

    const handleSelectAll = e => {
      if (selected.length === rows.length) {
        this.setState({ selected: [] });
      } else {
        this.setState({ selected: rows.map(o => o.order_id) });
      }
    };

    if (this.state.type === 'staging') {
      if(shop.aggregate == 1) {
        return (
          <tr className="cursor-default">
            <td style={headerStyle} className="table-cell-sticky">{!!rows.length && <input type="checkbox" checked={selected.length === rows.length} onClick={handleSelectAll} />}</td>
            {this.renderSortColumnHeader('date_created', 'Created')}
            {this.renderSortColumnHeader('customer_po', 'Reference')}
            {this.renderSortColumnHeader('checked_out_by', 'Customer Name')}
            {this.renderSortColumnHeader('checked_out_email', 'Email')}
            {this.renderSortColumnHeader('total_subtotal', 'Value')}
          </tr>
        );
      }else{
        return (
          <tr className="cursor-default">
            {this.renderSortColumnHeader('date_created', 'Created')}
            {this.renderSortColumnHeader('billing_contact', 'Customer Name')}
            {this.renderSortColumnHeader('billing_address_company', 'Company Name')}
            {this.renderSortColumnHeader('billing_contact_email', 'Email')}
            {this.renderSortColumnHeader('billing_address_line_1', 'Address')}
            {this.renderSortColumnHeader('total_subtotal', 'Value')}
          </tr>
        );
      }
    }

    if (this.state.type === 'completed') {
      return (
        <tr className="cursor-default">
          {this.renderSortColumnHeader('job_number', 'Project Name')}
          {this.renderSortColumnHeader('date_created', 'SO Created')}
          {this.renderSortColumnHeader('client_name', 'Client Name')}
          {this.renderSortColumnHeader('billing_contact', 'Billing Contact')}
          {this.renderSortColumnHeader('total_subtotal', 'Value')}
          {this.renderSortColumnHeader('status_name', 'Status')}
        </tr>
      );
    }

    if (this.state.type === 'charged') {
      return (
        <tr className="cursor-default">
          {this.renderSortColumnHeader('job_number', 'Project Name')}
          {this.renderSortColumnHeader('date_created', 'Invoice Created')}
          {this.renderSortColumnHeader('client_name', 'Client Name')}
          {this.renderSortColumnHeader('total_subtotal', 'Value')}
          {this.renderSortColumnHeader('charged_fee', 'Fee')}
          {this.renderSortColumnHeader('charged_date', 'Fee Date')}
        </tr>
      );
    }
  }

  renderRow(o) {
    const { shop, onAssignToClient } = this.props;
    const { selected, type } = this.state;

    const handleClickAssign = order => e => {
      e.preventDefault();
      onAssignToClient(order);
    };

    const handleClickOrder = order_id => e => {
      if (selected.includes(order_id)) {
        this.setState({ selected: selected.filter(id => id !== order_id) });
      } else {
        this.setState({ selected: selected.concat(order_id) });
      }
    };

    if(type === 'staging') {
      if(shop.aggregate == 1) {
        return (
          <tr key={o.order_id} onClick={handleClickOrder(o.order_id)}>
            <td><input type="checkbox" checked={selected.includes(o.order_id)} /></td>
            <td>{parseMysqlDate(o.date_created)}</td>
            <td>
              <a href={getAbsoluteUrl(`public_order.php?order_id=${o.order_id}`)} target="_blank" rel="noopener noreferrer">
                {o.customer_po}
              </a>
            </td>
            <td>{o.checked_out_by}</td>
            <td>{o.checked_out_email}</td>
            <td style={{ textAlign: 'right' }}>${formatMoney(o.total_subtotal)}</td>
          </tr>
        );
      }else{
        return (
          <tr key={o.order_id} onClick={handleClickAssign(o)}>
            <td>{parseMysqlDate(o.date_created)}</td>
            <td>{o.billing_contact_first_name} {o.billing_contact_last_name}</td>
            <td>{o.billing_address_company}</td>
            <td>{o.billing_contact_email}</td>
            <td>
              <Address
                address_line_1={o.billing_address_line_1}
                address_line_2={o.billing_address_line_2}
                address_city={o.billing_address_city}
                address_state={o.billing_address_state}
                address_postal={o.billing_address_postal}
                address_country={o.billing_address_country}
              />
            </td>
            <td style={{ textAlign: 'right' }}>${formatMoney(o.total_subtotal)}</td>
          </tr>
        );
      }
    }

    if(type === 'completed') {
      return (
        <tr key={o.order_id} onClick={e => { e.preventDefault(); window.open(`/project/${o.job_number}`, '_blank');}}>
          <td>#{o.job_number} &mdash; {o.job_name}</td>
          <td>{parseMysqlDate(o.date_created)}</td>
          <td>{o.client_name}</td>
          <td>{o.billing_contact_first_name} {o.billing_contact_last_name}</td>
          <td style={{ textAlign: 'right' }}>${formatMoney(o.total_subtotal)}</td>
          <td>{o.status_name}</td>
        </tr>
      );
    }

    if(type === 'charged') {
      return (
        <tr key={o.order_id} onClick={e => { e.preventDefault(); window.open(`${window.S3_URL}/charged_invoices/${o.order_id}`, '_blank'); }}>
          <td>#{o.job_number} &mdash; {o.job_name}</td>
          <td>{parseMysqlDate(o.date_created)}</td>
          <td>{o.client_name}</td>
          <td style={{ textAlign: 'right' }}>${formatMoney(o.charged_order_subtotal)} {o.charged_order_currency}</td>
          <td style={{ textAlign: 'right' }}>${formatMoney(o.charged_fee)} {o.charged_currency_converted}</td>
          <td>{parseMysqlDate(o.charged_date)}</td>
        </tr>
      );
    }
  }

  renderNoRow() {
    return (
      <tr><td colSpan="6" style={{ textAlign: 'center' }}>No orders yet</td></tr>
    );
  }

  render() {
    const { status_id, sales_rep_id, sort, loading, type, selected, rows } = this.state;
    const { shop, status_options, user_options, onJoinOrders, onCreatePartialPopup, onCreateAddToOrderPopup, onCreateDeletePartials, onCreateErrorPopup } = this.props;

    return (
      <>
      <div className="main-content">
        <div className="row full-width">
          <ul className="tabs" data-tabs style={{background: '#EDF2F5', border: 'none'}}>
            {shop.aggregate == 1 || !shop.client_id ?
              <li className={'tabs-title' + (this.state.type === 'staging' ? ' is-active' : '')} style={this.state.is_below ? { background: 'white' } : null}>
                <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('staging', 'type'); }} aria-selected={this.state.type === 'staging'}>Checkouts</a>
              </li>
            : null}
            <li className={'tabs-title' + (this.state.type === 'completed' ? ' is-active' : '')}>
              <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('completed', 'type'); }} aria-selected={this.state.type === 'completed'}>Orders</a>
            </li>
            <li className={'tabs-title' + (this.state.type === 'charged' ? ' is-active' : '')}>
              <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('charged', 'type'); }} aria-selected={this.state.type === 'charged'}>Fees</a>
            </li>
            {type === 'completed' ? <button type="button" className="small button" onClick={e => { e.preventDefault(); this.handleReset(); }} style={{margin: '12px 10px 0px 0px', float: 'right'}}>Reset</button> : null}
            {shop.aggregate == 1 && type === 'staging' && <>
              <Button size='small' variant='error' style={{ margin: '12px 10px 0px 0px', float: 'right' }} onClick={() => shop.in_aggregating == 1 ? onCreateErrorPopup() : onCreateDeletePartials(selected, rows.length === selected.length)} disabled={!selected.length}>Delete Checkouts</Button>
              <Button size='small' style={{margin: '12px 10px 0px 0px', float: 'right'}} onClick={() => shop.in_aggregating == 1 ? onCreateErrorPopup() : onJoinOrders(selected)} disabled={!selected.length}>Create Project</Button>
              <Button size='small' style={{margin: '12px 10px 0px 0px', float: 'right'}} onClick={() => shop.in_aggregating == 1 ? onCreateErrorPopup() : onCreateAddToOrderPopup(selected)} disabled={!selected.length}>Add to Order</Button>
              <Dropdown
                style={{ margin: '12px 10px 0px 0px', float: 'right' }}
                buttonProps={{ disabled: !selected.length }}
                buttonVariant='primary'
                size='small'
                text="Export Checkouts"
                items={[
                  {
                    onClick: e => {e?.preventDefault?.(); this.exportPartialsCSV(selected);},
                    content: "Export CSV",
                    active: selected.length > 0,
                    show: selected.length > 0,
                  },
                  {
                    onClick: e => {e?.preventDefault?.(); this.exportPartialsPDF(selected);},
                    content: "Export PDF",
                    active: selected.length > 0,
                    show: selected.length > 0,
                  },
                ]} />
              <Button size='small' style={{ margin: '12px 10px 0px 0px', float: 'right' }} onClick={() => onCreatePartialPopup(selected)} disabled={!selected.length}>View Checkouts</Button>
              <Typography.Span bold fontSize={'14px'} style={{margin: '18px 10px 0px 0px', float: 'right'}}>
                {selected.length > 0 ? `${selected.length.toLocaleString()} selected` : 'Select one or more checkouts'}
              </Typography.Span>
            </>}
          </ul>
        </div>
        {type === 'completed' ?
          <div className="row collapse" style={{ maxWidth: 'initial', marginTop: '10px', fontSize: '14px' }}>
            {shop.aggregate == 1 ? <LabelledInput className="medium-4 columns" placeholder="Customer Name, Email or Invoice Number" value={this.state.keyword} onChange={e => { this.handleChangeInput(e); this.handleSearch(); }} /> : null}
            <Select className="medium-4 columns" options={status_options} value={this.state.status_id} change={e => this.handleChangeFilter(e, 'status_id')} withMarginBottom />
            <Select className="medium-4 columns" options={user_options} value={this.state.sales_rep_id} change={e => this.handleChangeFilter(e, 'sales_rep_id')} withMarginBottom />
          </div>
        : null}
        {loading ?
          this.renderLoading()
        :
          <table style={{ marginBottom: 0 }}>
            <thead style={{ fontSize: '14px' }}>
              {this.renderHeader()}
            </thead>
            <tbody style={{ fontSize: '14px' }}>
              {rows.length ?
                rows.map(o =>
                  this.renderRow(o)
                )
              : this.renderNoRow()}
            </tbody>
          </table>
        }
      </div>
      {this.state.exportingPdf
        ? <ExportViewerPopup
            onClose={() => this.setState({ exportingPdf: false, exportPdfFile: undefined, exportReportTask: null, })}
            exportTask={this.state.exportReportTask}
            file={this.state.exportPdfFile}
          />
        : (
          this.state.exportTaskIds.length > 0
            ? <ExportViewerPortal>
                <ExportViewer exportTaskIds={this.state.exportTaskIds} />
              </ExportViewerPortal>
            : null
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  status_options: [{ key: 'ALL', value: 'All Statuses' }].concat(getStatusOptions(state, { order_type: 'SALES ORDER' })),
  user_options: [{ key: 'ALL', value: 'Assigned to anyone' }].concat(getUserOptions(state)),
  shipping_address: getAddress(state, { address_id: ownProps.shop.shipping_address_id }),
  order: getOrderByNumber(state, { form_number: ownProps.shop.shop_number, order_type: 'SHOP' }),
  inventory: getInventoryByShopId(state, ownProps.shop),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadOrder: order_id => dispatch(createLoadOrder(order_id)),
  loadInventory: () => dispatch(loadInventory(ownProps.shop.inventory_id)),
  loadShopOrders: () => dispatch(loadShopOrders(ownProps.shop.shop_id)),
  onJoinOrders: order_ids => {
    dispatch(createJoinOrdersPopup(ownProps.shop.shop_id, ownProps.shop.client_id, order_ids));
  },
  onAssignToClient: order => dispatch(createAssignToClientPopup(order, ownProps.shop.client_rep_id)),
  onCreatePartialPopup: order_ids => dispatch(createPartialPopup(order_ids)),
  onCreateAddToOrderPopup: order_ids => {
    dispatch(createAddToOrderPopup(order_ids, ownProps.shop.sales_orders.filter(so =>
      ownProps.shop.allow_credit_card_checkout == 1
        ? !!so.client_id && so.status_name === 'New' && (ownProps.shop.shipping_address_id === null || so.shipping_address_id === ownProps.shop.shipping_address_id)
          : !!so.client_id && so.status_name === 'New'
      ), ownProps.shop.shop_id));
  },
  onCreateDeletePartials: (order_ids, isSelectedAll=false) => {
    dispatch(createValidateConfirmationPopup(() => {
      const orderIds = isSelectedAll ? 'ALL' : order_ids;
      return dispatch(createDeletePartials(orderIds, ownProps.shop.shop_id));
    }, []));
  },
  onCreateErrorPopup: () => dispatch(createErrorPopup('There is a adding checkout process running for this shop. Please wait for it to finish first.')),
  onCreateNoticePopup: () => dispatch(createNoticePopup('Adding checkouts is in progress. It may take a while so please grab a coffee.'))
});

const ConnectedOrders = connect(mapStateToProps, mapDispatchToProps)(Orders);
export default ConnectedOrders;
